<template>
  <el-form 
  ref="form"
  label-width="150px"
  :model="formModel"
  :rules="ruleValidate">
    <el-form-item label="所属学校" prop="schoolId">
        <el-select-tree
        size="mediumn"
        :options="companyResult"
        v-model="formModel.schoolId"
        width="300px"
    ></el-select-tree>
    </el-form-item>
    <el-form-item>
    <el-button type="primary" @click="exportXls" :loading="loading">导出</el-button>
  </el-form-item>
</el-form>
</template>
<script>
import Constant from "@/constant";
import companyInfoApi from "@/api/base/companyInfo";
import examInfoApi from "@/api/business/examInfo";
import SelectTree from "@/components/SelectTree";
import { getToken } from "@/utils/auth"; // get token from cookie

export default { 
  props: ["companyResult"],
  data() {
      return {
        ruleValidate: {
            schoolId: [{ required: true, message: "请选择学校", trigger: "blur" }]
        },
        formModel: {
            schoolId: ""
        },
        //companyResult: [],
        fileList: [],
        loading: false
      }
  },
  methods: {
      exportXls() {
        this.$refs["form"].validate(valid=>{
            this.loading = true;

            examInfoApi.exportXls(this.formModel.schoolId).then(response=>{
                this.loading = false;
                var jsonData = response.data;

                if (jsonData.result) {
                    this.$message({
                        type: "success",
                        message: `报表已生成，<a href="${jsonData.data}">请点击链接下载</a>`,
                        dangerouslyUseHTMLString: true,
                        duration: 30000
                    });
                } else {
                    this.$message({
                        type: "warning",
                        message: jsonData.message
                    });
                }
            });
        });
      }
  },
  components: {
    "el-select-tree": SelectTree
  },
  created() {
    var self = this;

    // companyInfoApi.treeList().then(function (response) {
    //   var jsonData = response.data;

    //   if (jsonData.result) {
    //     if (jsonData.data != null) {
    //       self.companyResult = jsonData.data;
    //     }
    //   }
    // });
  }
}
</script>
<style>

</style>