<template>
  <div>
    <el-breadcrumb separator=">">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="#">校园生活</a>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <a href="/business/examInfo/list">学生成绩单</a>
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-divider></el-divider>
    <!--
      要resetFields起作用，必须配置:model和prop
    -->
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="考试名称" prop="name">
        <el-input type="text" size="mini" v-model="queryModel.name"></el-input>
      </el-form-item>
      <el-form-item label="选择单位" prop="companyId">
        <el-select-tree
            size="mini"
            :props="props"
            :options="companyResult"
            v-model="queryModel.companyId"
            height="200"
        ></el-select-tree>&nbsp;
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        @click="handleExport"
        >导出学生名单</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        @click="handleImport"
        >导入成绩单</el-button
      >
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-s-promotion"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchSendMessage"
        >发送通知</el-button
      >      
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-circle-plus"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchDelete"
        >删除选中项</el-button
      >
    </el-row>
    <el-table
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="schoolName"
        label="所属学校"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="gradeName"
        label="所属年级"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="name"
        label="考试名称"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="subjects"
        label="考试科目"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="studentNum"
        label="学生人数"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="receiveNum"
        label="已通知人数"
        width="180"
      ></el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="180"
      ></el-table-column>
      <el-table-column label="操作" fixed="right" width="200">
        <template slot-scope="{ row }">
          <el-button size="mini" type="success" @click="handleView(row)"
            >查看</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(row)"
            >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
    <el-dialog
          :visible.sync="showScoreDialog"
          title="学生成绩单"
          :modal-append-to-body="true"
          :append-to-body="true"
          width="1000px"
          style="text-align:left;"
          :close-on-click-modal="false"
      >
        <exam-score-list
          ref="examScoreList"
          :examId="selectedRow.id"
        ></exam-score-list>
    </el-dialog>
    <el-dialog
          :visible.sync="showImportDialog"
          title="导入成绩单"
          :modal-append-to-body="true"
          :append-to-body="true"
          width="800px"
          style="text-align:left;"
          :close-on-click-modal="false"
      >
      <exam-info-import @on-success="importSuccess" :companyResult="companyResult"></exam-info-import>
    </el-dialog>
    <el-dialog
          :visible.sync="showExportDialog"
          title="导出学生名单"
          :modal-append-to-body="true"
          :append-to-body="true"
          width="600px"
          style="text-align:left;"
          :close-on-click-modal="false"
      >
      <exam-info-export :companyResult="companyResult"></exam-info-export>
    </el-dialog>
  </div>
</template>
<script>
import Constant from "@/constant";
import examInfoApi from "@/api/business/examInfo";
import companyInfoApi from "@/api/base/companyInfo";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
import SelectTree from "@/components/SelectTree";
import ExamScoreList from '@/views/business/examScore-list';
import ExamInfoImport from './examInfo-import.vue';
import ExamInfoExport from './examInfo-export.vue';

export default {
  name: "businessExamInfoList",
  data() {
    var self = this;

    return {
      queryModel: {
        name: "",
        companyId: "",
      },
      loading: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: [],
      showModal: false,
      modalTitle: "",
      businessKey: "",
      companyResult: [],
      props: {
        // 配置项（必选）
        value: "id",
        label: "name",
        children: "children",
      },
      showScoreDialog: false,
      selectedRow: {},
      showImportDialog: false,
      showExportDialog: false
    };
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);

      formData.append("name", self.queryModel.name);
      formData.append("companyId", self.queryModel.companyId);

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      examInfoApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      this.field = data.column.field;
      this.direction = data.order;

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleExport() {
      this.showExportDialog = true;
    },
    handleImport() {
      this.showImportDialog = true;
    },
    handleView(row) {
      this.selectedRow = row;
      this.showScoreDialog = true;
      
      this.$nextTick(()=>{
          this.$refs.examScoreList.init();
      });
    },
    handleDelete(record) {
      var self = this;

      self
        .$confirm("是否确认删除?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          examInfoApi.remove(record.id).then(function (response) {
            var jsonData = response.data;

            if (jsonData.result) {
              // var index = self.tableData.indexOf(record);
              // self.tableData.splice(index, 1);
              self.changePage(self.pageIndex);

              self.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        });
    },
    handleBatchDelete() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否确认删除选中项？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        examInfoApi.batchRemove(idList).then(function (response) {
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "删除成功!",
            });
          }
        });
      });
    },
    handleBatchSendMessage() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否发送通知？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        examInfoApi.sendMessage(idList).then(function (response) {
          var jsonData = response.data;
          
          console.log(jsonData);

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message.success("发送请求成功!请稍后刷新页面查看结果。");
          }
          else{
            self.$message.warning(jsonData.message);
          }
        });
      });
    },
    onDetailModalClose(refreshed) {
      //保存成功后回调
      this.showModal = false;

      if (refreshed) {
        this.changePage(this.pageIndex);
      }
    },
    importSuccess() {
      this.showImportDialog = false;
      this.changePage(1);
    }
  },
  mounted: function () {
    this.changePage(1);
  },
  created() {
    var self = this;

    companyInfoApi.treeList().then(function (response) {
      var jsonData = response.data;

      if (jsonData.result) {
        self.companyResult = jsonData.data;
      }
    });
  },
  components: {
    "el-select-tree": SelectTree,
    'exam-score-list': ExamScoreList,
    'exam-info-import' : ExamInfoImport,
    'exam-info-export': ExamInfoExport
  }
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>