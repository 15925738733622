import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/business/examInfo/pageList", formData);
}

function create(){
  return request.get(constant.serverUrl + "/business/examInfo/create");
}

function edit(id){
  return request.get(constant.serverUrl + "/business/examInfo/edit/" + id);
}

function add(formModel){
  return request.post(constant.serverUrl + "/business/examInfo/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/business/examInfo/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id){
  return request.post(constant.serverUrl + "/business/examInfo/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/business/examInfo/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function sendMessage(idList){
  return request.post(constant.serverUrl + "/business/examInfo/sendMessage",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}


function exportXls(schoolId) {
  return request.get(constant.serverUrl + `/business/examInfo/exportXls?schoolId=${schoolId}`);
}

export default {
  pageList,create,edit,add,update,remove,batchRemove,exportXls,sendMessage
}