import request from '@/utils/request'
import constant from '@/constant'

function pageList(formData){
  return request.post(constant.serverUrl + "/business/examScore/pageList", formData);
}

function create(){
  return request.get(constant.serverUrl + "/business/examScore/create");
}

function edit(id){
  return request.get(constant.serverUrl + "/business/examScore/edit/" + id);
}

function add(formModel){
  return request.post(constant.serverUrl + "/business/examScore/add", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function update(formModel){  
  return request.post(constant.serverUrl + "/business/examScore/update", formModel,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function remove(id){
  return request.post(constant.serverUrl + "/business/examScore/delete/" + id);
}

function batchRemove(idList){
  return request.post(constant.serverUrl + "/business/examScore/batchDelete",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

function sendMessage(idList){
  return request.post(constant.serverUrl + "/business/examScore/sendMessage",idList,{
    headers: {
      "Content-Type": "application/json"
    }
  });
}

export default {
  pageList,create,edit,add,update,remove,batchRemove,sendMessage
}