<template>
  <el-form 
  ref="form"
  label-width="150px"
  :model="formModel"
  :rules="ruleValidate">
        <el-form-item label="所属学校" prop="schoolId">
          <el-select-tree
            size="mediumn"
            :options="companyResult"
            v-model="formModel.schoolId"
            width="300px"
        ></el-select-tree>
        </el-form-item>
        <el-form-item label="考试名称" prop="examName">
            <el-input type="text" size="mediumn" v-model="formModel.examName"></el-input>
        </el-form-item>
        <el-form-item label="选择文件">
          <el-upload
            accept=".xls,.xlsx"
            :action="uploadUrl"
            :data="formModel"
            :headers="headers"
            name="uploadFile"
            :multiple="false"
            :before-upload="beforeUpload"
            :on-success="uploadSuccess"
            :file-list="fileList"
          >
            <el-button size="small" type="primary" :loading="loading"
              >点击上传</el-button>
          </el-upload>
        </el-form-item>
      </el-form>
</template>
<script>
import Constant from "@/constant";
import companyInfoApi from "@/api/base/companyInfo";
import SelectTree from "@/components/SelectTree";
import { getToken } from "@/utils/auth"; // get token from cookie

export default { 
  props: ["companyResult"],
  data() {
      return {
        headers: {
            Authorization: getToken(),
        },
        uploadUrl: Constant.serverUrl + "/business/examScore/importXls",
        ruleValidate: {
            schoolId: [{ required: true, message: "请选择学校", trigger: "blur" }],
            examName: [{ required: true, message: "考试名称不能为空", trigger: "blur" }]
        },
        formModel: {
            schoolId: "",
            examName: ""
        },
        //companyResult: [],
        fileList: [],
        loading: false
      }
  },
  methods: {
      beforeUpload() {
        this.$refs["form"].validate(valid=>{

        });

        //必须选择学校及填写考试名称
        if(this.formModel.schoolId.length==0){
            return false;
        }

        if(this.formModel.examName.length==0){
            return false;
        } 

        this.loading = true;

        return true;
      },
      uploadSuccess(response, file, fileList) {
        this.loading = false;

        if (response.result) {
            this.$message.success(response.message);
            this.$emit("on-success");
        }
        else{
            this.$message.error(response.message);
        }
      }
  },
  components: {
    "el-select-tree": SelectTree
  },
  created() {
    var self = this;

    // companyInfoApi.treeList().then(function (response) {
    //   var jsonData = response.data;

    //   if (jsonData.result) {
    //     if (jsonData.data != null) {
    //       self.companyResult = jsonData.data;
    //     }
    //   }
    // });
  }
}
</script>
<style>

</style>