<template>
  <div>
    <el-form
      ref="queryForm"
      :model="queryModel"
      inline
      class="demo-form-inline"
    >
      <el-form-item label="所属班级" prop="className">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.className"
        ></el-input>
      </el-form-item>
      <el-form-item label="学生姓名" prop="studentName">
        <el-input
          type="text"
          size="mini"
          v-model="queryModel.studentName"
        ></el-input>
      </el-form-item>
      <el-form-item label="通知状态" prop="receiveFlag">
        <el-select
          size="mini"
          v-model="queryModel.receiveFlag"
        >
          <el-option value="" label="未选择"></el-option>
          <el-option value="0" label="未通知"></el-option>
          <el-option value="1" label="已通知"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          size="mini"
          icon="ios-search"
          @click="changePage(1)"
          :loading="loading"
          >查询</el-button
        >&nbsp;
        <el-button
          type="info"
          size="mini"
          style="margin-left: 8px"
          @click="handleReset('queryForm')"
          >重置</el-button
        >&nbsp;
      </el-form-item>
    </el-form>
    <el-divider></el-divider>
    <el-row class="button-group">    
      <el-button
        type="primary"
        size="small"
        plain
        icon="el-icon-s-promotion"
        :disabled="multipleSelection.length == 0"
        @click="handleBatchSendMessage"
        >发送通知</el-button
      >      
    </el-row>
    <el-table
      :data="tableData"
      style="min-height: 400px"
      v-loading="loading"
      stripe
      @sort-change="sortChange"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column
        prop="studentName"
        label="学生姓名"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="className"
        label="班级名称"
        width="120"
      ></el-table-column>
      <template v-for="(subject, index) in subjects">
        <el-table-column        
          :sort-by="'t1.score' + (index + 1) + '_'"
          sortable="custom"
          :key="'score' + (index + 1)"
          :label="subject"
          width="100"
        >
         <template slot-scope="{ row }">
           {{row['score' + (index + 1)]}}
           <span v-if="row['extra' + (index + 1)]!=null">+{{row['extra' + (index + 1)]}}</span>
         </template>
        </el-table-column>
      </template>
      <el-table-column
        prop="totalScore"
        label="总分"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="receiveFlag"
        label="发送状态"
        width="100"
      >
         <template slot-scope="{ row }">
           <span v-if="row.receiveFlag" style="color:green">已发送</span>
           <span v-else style="color:gray">未发送</span>
         </template>      
      </el-table-column>
      <el-table-column
        prop="createTime"
        label="创建时间"
        width="160"
      ></el-table-column>
      <el-table-column
        prop="updateTime"
        label="发送时间"
        width="160"
      ></el-table-column>
    </el-table>
    <el-pagination
      :current-page.sync="pageIndex"
      :total="totalElements"
      :page-sizes="pageSizeList"
      @current-change="changePage"
      @size-change="pageSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
    ></el-pagination>
  </div>
</template>
<script>
import Constant from "@/constant";
import examInfoApi from "@/api/business/examInfo";
import examScoreApi from "@/api/business/examScore";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

export default {
  props: ["examId"],
  data() {
    var self = this;

    return {
      queryModel: {
        studentName: "",
        className: "",
        receiveFlag: ""
      },
      loading: false,
      subjects: [],
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      totalPages: 0,
      totalElements: 0,
      field: "",
      direction: "",
      pageSizeList: [10, 20, 30],
      multipleSelection: []
    };
  },
  methods: {
    changePage(pageIndex) {
      var self = this;

      self.loading = true;

      self.pageIndex = pageIndex;
      var formData = new FormData();

      formData.append("pageIndex", self.pageIndex);
      formData.append("pageSize", self.pageSize);
      formData.append("examId", self.examId);

      formData.append("studentName", self.queryModel.studentName);
      formData.append("className", self.queryModel.className);

      if(self.queryModel.receiveFlag!=null && self.queryModel.receiveFlag.length>0){
        formData.append("receiveFlag", self.queryModel.receiveFlag);
      }

      if (this.field != null) {
        formData.append("field", this.field);
      }

      if (this.direction != null) {
        formData.append("direction", this.direction);
      }

      examScoreApi
        .pageList(formData)
        .then(function (response) {
          self.loading = false;

          var jsonData = response.data.data;

          self.tableData = jsonData.data;
          self.totalPages = jsonData.totalPages;
          self.totalElements = jsonData.recordsTotal;
        })
        .catch((error) => {
          self.loading = false;
          // self.$message.error(error + "");
        });
    },
    pageSizeChange(pageSize) {
      this.pageSize = pageSize;

      this.$nextTick(() => {
        this.changePage(this.pageIndex);
      });
    },
    sortChange(data) {
      console.log(data.column);
      this.field = data.column.sortBy;
      this.direction = data.order == "ascending" ? "asc" : "desc";

      this.changePage(this.pageIndex);
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd() {
      this.modalTitle = "新增";
      this.businessKey = "";
      this.showModal = true;
    },
    handleEdit(record) {
      this.modalTitle = "编辑";
      this.businessKey = record.id;
      this.showModal = true;
    },
    init() {
      examInfoApi.edit(this.examId).then((response) => {
        var jsonData = response.data;

        this.subjects = jsonData.data.subjects.split(",");

        this.changePage(1);
      });
    },    
    handleBatchSendMessage() {
      var self = this;

      var idList = this.multipleSelection.map((record) => {
        return record.id;
      });

      this.$confirm("是否发送通知？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        self.loading = true;

        examScoreApi.sendMessage(idList).then(function (response) {
          self.loading = false;
          
          var jsonData = response.data;

          if (jsonData.result) {
            self.changePage(self.pageIndex);

            self.$message({
              type: "success",
              message: "发送成功!",
            });
          }
        });
      });
    },
  },
  mounted: function () {},
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  margin: 10px;
  line-height: 20px;
}

.el-divider {
  margin: 5px 0;
}

.demo-form-inline {
  margin-left: 10px;
  text-align: left;
}

.button-group {
  margin-left: 10px;
  text-align: left;
}
</style>